import React from "react"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import scrollTo from "gatsby-plugin-smoothscroll"
import { css } from "@emotion/core"
import { graphql } from "gatsby"
import { ArrowDownIcon2 } from "../components/socialIcons"
import { Screen } from "../responsive"
import { theme } from "../components/theme"
import { Css } from "../components/css"

const Section = props => {
  return (
    <section
      css={{
        margin: `3rem 0`,
      }}
      {...props}
    ></section>
  )
}

const AboutUsPage = ({ data }) => {
  return (
    <Layout>
      <SEO />
      <main
        css={{
          position: "relative",
        }}
      >
        <Section css={{ marginTop: 0 }}>
          <BackgroundImage
            style={{
              backgroundAttachment: "fixed",
              backgroundPosition: "center",
              width: "100%",
              height: "95vh",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
            fluid={data.photoshootEight.childImageSharp.fluid}
            alt={"Laumes - architekturos studija"}
          >
            <span
              css={{
                height: "70%",
                display: "flex",
                flexDirection: "center",
                margin: "0 auto",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <div
                css={{
                  position: "relative",
                  top: "75%",
                }}
              >
                <a target="_blank" rel="noopener noreferrer">
                  <button
                    onClick={() => scrollTo("#jump_to")}
                    css={{
                      outline: "none",
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                  >
                    <ArrowDownIcon2 />
                  </button>
                </a>
              </div>
            </span>
          </BackgroundImage>
          <div id="jump_to" />
        </Section>
        <Section
          css={{
            ...Css.container,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: `4rem auto`,
            padding: `0 2rem`,
            textAlign: "center",
            maxWidth: "950px",
            width: "auto",
          }}
        >
          <h2
            css={css`
              font-size: 2.4rem;
              line-height: 1.3rem;
              letter-spacing: 0.2rem;
              max-width: 650px;
            `}
          >
            LAUMĖS
          </h2>

          <p>
            Trijų architekčių komanda, savo darbo profilį koncentruojanti į
            ekonomiškos ir funkcionalios, kartu jautrios minimalistinės
            estetikos architektūrą. Laumių įkvėpimo šaltinis beribė gamta,
            minimalistinė švara ir etniniai aruodai, kurie per detales
            meistriškai sugula į jūsų gyvenimo scenarijus.
            <div css={{ width: "auto" }}>Kam namai, o kam pasaka!</div>
          </p>
        </Section>

        <PeopleSection data={data} />

        <Section
          css={{
            ...Css.responsiveSection,
          }}
        >
          <div
            css={{
              display: "grid",
              gridGap: "1rem",
              paddingRight: "1rem",
              paddingLeft: "1rem",
              gridTemplateColumns: "repeat(auto-fit, minmax(186px, 1fr));",
              gridAutoRows: "45vh",
            }}
          >
            <Img
              fluid={data.photoshootNine.childImageSharp.fluid}
              alt="Laumes - architekturos studija"
              css={{ gridArea: "span 2" }}
            />
            <Img
              fluid={data.photoshootThree.childImageSharp.fluid}
              alt="Laumes - architekturos studija"
            />
            <Img
              fluid={data.photoshootTwelve.childImageSharp.fluid}
              alt="Laumes - architekturos studija"
            />
            <Img
              fluid={data.photoshootSeven.childImageSharp.fluid}
              alt="Laumes - architekturos studija"
            />
            <Img
              fluid={data.photoshootEleven.childImageSharp.fluid}
              alt="Laumes - architekturos studija"
              css={{ gridArea: "span 2" }}
            />
            <Img
              fluid={data.photoshoot.childImageSharp.fluid}
              alt="Laumes - architekturos studija"
            />
            <Img
              fluid={data.photoshootTen.childImageSharp.fluid}
              alt="Laumes - architekturos studija"
              css={{ gridArea: "span 2" }}
            />
            <Img
              fluid={data.photoshootSix.childImageSharp.fluid}
              alt="Laumes - architekturos studija"
            />
            <Img
              fluid={data.photoshootFive.childImageSharp.fluid}
              alt="Laumes - architekturos studija"
            />
            <Img
              fluid={data.photoshootOne.childImageSharp.fluid}
              alt="Laumes - architekturos studija"
            />
            <Img
              fluid={data.photoshootTwo.childImageSharp.fluid}
              alt="Laumes - architekturos studija"
            />
          </div>
        </Section>
      </main>
    </Layout>
  )
}

function PeopleSection({ data }) {
  const teamMembers = [
    {
      title: "Agnė",
      subtitle: "PAJŪRIO LAUMĖ",
      description: `Kopų smėlio ir saulės, išmyluotas ir įkvėptas vaikas. Pasakiško grožio
      deivė - su įgimta akimi detalėms. Agnė Laumių komandoje dar žinoma kaip
      “keliautoja - laiku” - laužanti visus pasaulio rekordus. Apsigimusi CEO,
      priimanti esminius sprendimus, kol kitos dvi Laumės sklando variacijų
      galaktikoje. Laumiška jos šypsena pavergia tolimiausių kraštų herojus, o
      idėjos parsiveštos iš už jūrų marių - įkvepia vietinius. Jos dėmesiu su
      Laumėmis dalinasi komanda A - vyras Antanas su dukryte Amelija ir sūnumi Arnu.`,
      img: {
        fixed: data.agne.childImageSharp.fixed,
        alt: "Agne Petkuniene",
      },
    },
    {
      title: "Inga",
      subtitle: "DZŪKIJOS MIŠKŲ LAUMĖ",
      description: `Augusi mažučiame Dzūkijos miestelyje, apsuptame gamtos - įkvėpimo ir
      kūrybinio stimulo parsineša iš miškų. Inga stengiasi įsiklausyti į
      kiekvieno istorijas ir poreikius, kad galėtų jautriai ir originaliai
      perteikti vizijas kūryboje. Inga, Laumių įkvėpimas ir detalių
      meistrė, sugebanti pajausti erdvę ir prikviesti į ją pasaką. Būdama
      jauniausia Laume, Inga dar skrajoja medaus mėnesio nuotaikomis, ir
      su jaunikiu Martynu bando pažaboti vėjo aitvarus.`,
      img: {
        fixed: data.inga.childImageSharp.fixed,
        alt: "Inga Jankauskiene",
      },
    },
    {
      title: "Nora",
      subtitle: "CENTRINĖS LIETUVOS LAUMĖ",
      description: `Ideologinė Laumė. Jos mintys ir prisilietimas stebuklingai paverčia
      visus namus bene sakralia gerovės ir jaukumo vieta. Kūrybiškumas ir
      estetika jos kraujyje. Kadais minėtą, kaip “vyrišką” profesiją, Nora
      pažabojo ir palenkė savo pusėn jau seniai. Visų statybos darbų
      vadovų laimė ir baimė - statybų aikštelėje ji laisva kaip paukštė.
      Su vyru Giedriumi augindama Joną ir Eleną, daug dėmesio skiria
      natūralių ir tvarių sprendimų paieškomis architektūroje.`,
      img: {
        fixed: data.nora.childImageSharp.fixed,
        alt: "Nora Bernotiene",
      },
    },
  ]

  return (
    <Section>
      <div
        css={{
          margin: "4rem auto",
          display: "grid",
          gridGap: "4rem",
          gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))",
          padding: "0 2rem",
          ...Css.container,
        }}
      >
        {teamMembers.map((it, idx) => (
          <PeopleItem key={idx} {...it} />
        ))}
      </div>
    </Section>
  )
}

function PeopleItem(props) {
  const { img, title, subtitle, description } = props
  return (
    <div css={{ textAlign: "center" }}>
      <Img css={{ borderRadius: "50%" }} fixed={img.fixed} alt={img.alt} />
      <h3>{title}</h3>
      <p css={{ fontSize: "smaller", letterSpacing: "1.5px" }}>{subtitle}</p>
      <p css={{ fontSize: "smaller" }}>{description}</p>
    </div>
  )
}

export default AboutUsPage

export const pageQuery = graphql`
  query {
    agne: file(relativePath: { eq: "Agne.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    inga: file(relativePath: { eq: "Inga.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    nora: file(relativePath: { eq: "Nora.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    photoshoot: file(relativePath: { eq: "photoshoot.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    photoshootOne: file(relativePath: { eq: "photoshoot1.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    photoshootTwo: file(relativePath: { eq: "photoshoot2.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    photoshootThree: file(relativePath: { eq: "photoshoot3.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    photoshootFour: file(relativePath: { eq: "photoshoot4.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    photoshootFive: file(relativePath: { eq: "photoshoot5.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    photoshootSix: file(relativePath: { eq: "photoshoot6.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    photoshootSeven: file(relativePath: { eq: "photoshoot7.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    photoshootEight: file(relativePath: { eq: "photoshoot8.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 12000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    photoshootNine: file(relativePath: { eq: "photoshoot9.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    photoshootTen: file(relativePath: { eq: "photoshoot10.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    photoshootEleven: file(relativePath: { eq: "photoshoot11.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    photoshootTwelve: file(relativePath: { eq: "photoshoot12.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
